
/**
 * header search javascript file for header search
 */
var typeUrlPram;
var subtypeUrlPram;
$(document).ready(function() {
	typeUrlPram = $('#typeUrlParam').val() ? $('#typeUrlParam').val() : "ctype";
	subtypeUrlPram = $('#subtypeUrlParam').val() ? $('#subtypeUrlParam').val() : "ttype";
	if(decodeURIComponent(window.location.href).indexOf("subtype") > 0 && decodeURIComponent(window.location.href).indexOf("type=") > 0) {
		typeUrlPram = "type";
		subtypeUrlPram = "subtype"
	}
  $("#search-button-id").click(function(){
	  doOdpSearch(locale);
  });
  

  document.getElementById("search-input").setAttribute("placeholder", "Search Decision Point");
  
  

  //Adding search form in mobile 
  $(".slide-out-menu").prepend(
		"<div class='mobile-menu-item'>"+
		  "<form  id='search-form-header-mobile'>"+
			  "<button><img src='https://img.medscape.com/pi/sites/odp/icon/16-px-search.png' class='decision-combined-shape'></button>"+
			  "<input id='search-input-mobile' autocomplete='off' autocorrect='off' placeholder='Search Decision Point' type='text'>"+
			"<form>"+
		"</div>");

	$( "#search-form-header-mobile" ).submit(function( event ) {
		event.preventDefault();
		doOdpSearch(locale);
	});
	$("#search-form-header-mobile button").click(function(){
		doOdpSearch(locale);
	})
	$('.dp-title-block').on('click', () => {
		const pathname = window.location.pathname.split('/')[1];
		const url = window.location.origin + '/' + pathname;
		location.href = url;
    });
});

function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName, i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
}

function searchodp(){
	if (document.getElementById('searchdbvalue').value == "5") {
	       doOdpSearch(locale)
	  }

}

function doOdpSearch(locale) {
	
	    var qaChk = (document.domain.match(/\.qa\d\d/) == null ? "" : document.domain.match(/\.qa\d\d/)[0]);

	    var localeString = '';
	    if (locale != null && locale != 'en') {
	        localeString = '/' + locale;
	    }
	    var _qval = $('#search-input').val();
	   
		if(!_qval){
			_qval = $('#search-input-mobile').val();
		}
	    if(typeof _qval == "undefined") {
	        _qval = $('#searchInput').val();
	    }
	    
	     
	    if(_qval=="") {
	        _qval = $('#layer-search-input').val();
	    }
	    
	    if(typeof _qval == "undefined" || _qval==""){
	    	return;
	    }
	    
		if (_qval.indexOf('@') != -1) { // remove any search query if email address might be present
			_qval = '';
		}

		var ctype = $("input[type=hidden][name='cancer']").val();
		var ttype = $("input[type=hidden][name='tumor']").val();
		
		if (!ctype) {
			ctype = getUrlParameter(typeUrlPram);
			if (!ttype) {
				ttype = getUrlParameter(subtypeUrlPram);
			}		    
		}
		
		
		
		 var pageLink = window.location.pathname;
			
	       pageLink = pageLink .split("/");
	        
	        

	       var medicalField = pageLink[1];
			
			var urlpath = '/' + medicalField + '/search?q=' + _qval;
	      


		if (ctype) {
			urlpath = urlpath + '&'+ typeUrlPram + '=' + ctype;
			if (ttype) {
				urlpath = urlpath + '&'+ subtypeUrlPram + '=' + ttype;
			}

		}
		
		console.log("Redirect URL path : " + urlpath);
		window.location.href = urlpath;
		
}
